import React, { useState } from 'react';
import styled from '@emotion/styled';
import { useTheme } from 'emotion-theming';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { Button } from '../Button';

const MenuBtn = styled(FontAwesomeIcon)`
  cursor: pointer;
`;
const Dropdown = styled.div`
  width: 21.2rem;
  padding: 2.4rem 0.5rem;
  border: 1px solid;
  border-color: ${(props) => props.theme.colors.grey};
  background: ${(props) => props.theme.colors.fg};
  position: absolute;
  right: -8.65rem;
  top: 3rem;
  z-index: 1000;
  border-radius: 0.5rem;
  box-shadow: ${(props) => props.theme.boxShadow};
  text-align: center;
`;
const Arrow = styled.div`
  left: 50%;
  position: absolute;
  top: 0px;
  background-color: #fff;
  border-left: 1px solid rgba(0, 0, 0, 0.3);
  border-top: 1px solid rgba(0, 0, 0, 0.3);
  transform: translate(-50%, -50%) rotate(45deg);
  height: 16px;
  width: 16px;
`;

const CardDropdown = ({ options }) => {
  const theme = useTheme();

  const [showOptions, setShowOptions] = useState(false);

  return (
    <div>
      <MenuBtn icon={faEllipsisV} color={theme.colors.grey} onClick={() => setShowOptions(!showOptions)} />
      {showOptions && (
        <Dropdown>
          <Arrow />
          {options.map((option, idx) => (
            <Button key={idx} btnLink onClick={option.action}>
              {option.text}
            </Button>
          ))}
        </Dropdown>
      )}
    </div>
  );
};

export default CardDropdown;
