import React from "react";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { Flex } from "./index";
import { SiteTitle, Text } from "../Typography";
import CardDropdown from 'components/UI/Cards/CardDropdown.js';
import { useGlobalState } from "store";

export const HeaderBar = styled.header`
  height: ${props => props.theme.headerHeight || "7.9rem"};
  background-color: ${props => props.theme.colors.fg || "#FCFCFD"};
  box-shadow: 0 19px 58px -20px rgba(34, 46, 78, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
`;

export const Header = ({ left, right }) => {
  return (
    <HeaderBar>
      <div className="container">
        <Flex justify="space-between">
          <div>{left}</div>
          <div>{right}</div>
        </Flex>
      </div>
    </HeaderBar>
  );
};

export const AppHeader = ({ app, homeLink, icon, pageTitle, theme, history }) => {

  const [appState, appDispatch] = useGlobalState();

  const HeaderLeft = () => (
    <Flex align="center">
      {icon}
      <SiteTitle p="0" m="0 0.9rem 0 0.6rem">
        {homeLink ? (
          <Link
            to={homeLink}
            style={{
              color: "#222e4e",
              fontSize: "2.1rem",
              textDecoration: "none",
            }}>
            {app}
          </Link>
        ) : (
          app
        )}{" "}
        <Text color={theme.colors.blue} weight="300">
          {pageTitle}
        </Text>
      </SiteTitle>
    </Flex>
  );

  const HeaderRight = () => (
    <CardDropdown
      options={[
        {
          text: 'Sign Out',
          action: () => {
            localStorage.setItem("token", null);
            localStorage.setItem("cs_email", null);
            appDispatch({ type: "LOG_OUT" });
          }
        },
        {
          text: "Pre-Merged Campaigns Report",
          action: () => {
            history.push('/pre-merged-campaigns-report')
          }
        }
      ]}
    />
  );
  return <Header left={<HeaderLeft />} right={<HeaderRight />} />;
};
