import React, { useState, useEffect } from "react";
import { useGlobalState } from "store";
import APIService from "services/backstrap/apiService";
import { Button } from "components/UI/Button";
import PageLayout from "components/UI/Layout/PageLayout";
import { PageTitle, Text } from "components/UI/Typography";
import ClipLoader from 'react-spinners/ClipLoader';
import {LoadingContainer} from './styles';

const PreviewJobComponent = props => {
  const [appState, appDispatch] = useGlobalState();
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    data: [],
    jobs: [],
    submitted: false,
    accepted: false,
    get_preview: false
  });

  useEffect(() => {
    APIService.getPrintJobPreview(props.match.params.id)
    .then(response => {
      console.log(response);
      setState({ 
        data: response, 
        jobs: response.print_jobs
      });
    })
    .catch(error => {
      console.log(error);
    });
  }, []);

  function acceptJob() {
    const obj = { print_batch_id: state.data.id };
    setLoading(true);
    APIService.acceptPrintJob(obj)
    .then(response => {
      setLoading(false);
      console.log(response);
      setState({ ...state, accepted: response.success, submitted: true });
    })
    .catch(error => {
      setLoading(false);
      console.log(error);
      appDispatch({
        type: "SET_NOTIFICATION",
        payload: {
          type: "danger",
          message: "error submitting",
          active: true,
        },
      });
    });
  }

  function determineMailClass(mailClass) {
    if (mailClass === "FIRST_CLASS_CERTIFIED_ELECTRIC_RETURN_RECEIPT") {
      return "ERR";
    } else if (
      mailClass === "FIRST_CLASS_CERTIFIED_GREEN_CARD_RETURN_RECEIPT"
    ) {
      return "GC";
    } else {
      return "FC";
    }
  }

  if (loading) {
    return (
      <PageLayout props={props}>
        <LoadingContainer>
          <ClipLoader size={25} color={'#123abc'} />
        </LoadingContainer>
      </PageLayout>
    );
  }

  return (
    <PageLayout props={props}>
      {!state.submitted ? (
        <div id="previewJobContiner">
          <PageTitle m="0 0 3rem">Print Job Preview</PageTitle>

          {state.jobs.map((job, index) => {
            return (
              <div className="jobs" key={index}>
                <h4 className="jobId">
                  {job.display_name}
                </h4>
                <div>
                  <div>
                    <span>
                      Letters: <span>{job.letter_count}</span>
                    </span>{" "}
                  </div>
                  <div>
                    {" "}
                    <span>
                      Impressions: <span>{job.impression_count}</span>
                    </span>
                  </div>
                </div>
              </div>
            );
          })}

          <Button onClick={acceptJob}>Accept Print Job</Button>
        </div>
      ) : (
        <div id="confirmation">
          {state.accepted ? (
            <>
              <PageTitle m="0 0 3rem">Print Jobs created</PageTitle>
              <div className="mb-1">The following print jobs were created:</div>
              <div className="mb-5">
                {state.jobs &&
                  state.jobs.length &&
                  state.jobs.map(e => e.display_name + " ")}
              </div>
              <Button onClick={() => props.history.push("/")}>
                Go to Dashboard
              </Button>
            </>
          ) : (
            <>
              <PageTitle m="0 0 3rem">Print Jobs were not created</PageTitle>
              <div className="mb-5">Please try again</div>
              <Button onClick={() => props.history.push("/")}>
                Go to Dashboard
              </Button>
            </>
          )}
        </div>
      )}
    </PageLayout>
  );
};

export default PreviewJobComponent;
