const environment = "dev"
let apiUrl = "http://localhost:3000";

switch (environment) {
  case "dev":
    apiUrl = "https://dev-api.mailhouse.betatest.team/";
    break;
  case "staging":
    apiUrl = "https://staging-api.mailhouse.betatest.team/";
    break;
  case "production":
    apiUrl = "https://api.mailhouse.betatest.team/";
    break;
}

export const API_URL = apiUrl;

export const ENDPOINTS = {
  SIGN_IN: `/common/accounts/signIn`,
  GET_POST_OFFICES: `/v1/postoffice/index`,
  GET_CAMPAIGN_SEARCH: `/v1/campaign/search`,
  GET_PRINT_JOB: `/v1/printjob/campaigns`,
  GET_PRINT_JOB_DETAILS: `/v1/printjob/details`,
  SEARCH_PRINT_JOBS: `/v1/printjob/search`,
  GET_PRINT_JOB_PREVIEW: `/v1/printbatch/preview`,
  POST_PRINT_JOB: `/v1/printbatch`,
  ACCEPT_PRINT_JOB: `/v1/printbatch/accept`,
  TOGGLE_HIDDEN_CAMPAIGN: `/v1/campaign/hidden`,
  GET_CAMPAIGN_RECIPIENTS: `/v1/campaign/recipients`,
  PRINT_RUN: `/v1/printrun/print`,
  UPLOAD_RECOVERY_FILE: `/v1/printrun/upload_recovery_file`,
  SUBMIT_RECOVERY_FILES: `/v1/printrun/submit_recovery_files`,
  GET_RECOVERY_LETTERS: `/v1/printjob/recovery_letters`,
  POST_RECOVERY_RUN: `/v1/printjob/recovery_run`,
  LOAD_TRUCK: `/v1/printjob/loading`,
  INSERT_RECOVERY_LETTER: `/v1/letter/insert_result`,
  GREEN_CARD_IMPB: `/v1/letter/green_card_impb`,
  CONFIRM_MAIL_DROP: `/v1/printjob/mailed`,
  MARK_ALL_AS_COMPLETE: `/v1/printjob/mark_all_as_complete`,
  GET_PRE_MERGED_CAMPAIGNS: '/v1/reporting/pre_merged_campaigns',
  DOWNLOAD_PRE_MERGED_CAMPAIGNS: '/v1/reporting/pre_merged_campaigns_download',
  CHECK_TOKEN: `/common/accounts/checkToken`
};
