import React, { useState, useEffect, useRef } from "react";
import { useGlobalState } from "store";
import APIService from "services/backstrap/apiService";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import "./Login.css";

const SignIn = props => {
  const [appState, appDispatch] = useGlobalState();
  const [userName, setUsername] = useState("");
  const [password, setPassword] = useState("");

  function validateForm() {
    return userName.length > 0 && password.length > 0;
  }

  function handleSubmit(e) {
    e.preventDefault();
    APIService.signIn(userName, password)
    .then(res => {
      appDispatch({ type: "SIGN_IN_SUCCESS", payload: res });
      if (res.token) {
        localStorage.setItem("token", res.token);
      } 
      else {
        localStorage.setItem("token", null);
      }

      localStorage.setItem("cs_userName", res.userName);
      props.history.push("/");
    })
    .catch(err => {
      appDispatch({
        type: "SET_NOTIFICATION",
        payload: {
          type: "danger",
          message: 'Failed to sign in',
          active: true,
        },
      });
    });
  }

  return (
    <div className="Login">
      <h2 style={{textAlign: 'center', padding: 30}}>Welcome to Mailhouse</h2>
      <Form onSubmit={handleSubmit}>
        <Form.Group size="lg" controlId="userName">
          <Form.Label>Username</Form.Label>
          <Form.Control
            autoFocus
            type="username"
            value={userName}
            onChange={(e) => setUsername(e.target.value)}
          />
        </Form.Group>
        <Form.Group size="lg" controlId="password">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Form.Group>
        <Button block size="lg" type="submit" disabled={!validateForm()}>
          Login
        </Button>
      </Form>
    </div>
  );
};

export default SignIn;
